<script>
import faq from '~/components/layout/faq'

export default {
  name: 'AsununtolainaFAQAccordion',
  extends: faq,
  data () {
    return {
      questions: [
        {
          title: 'Kuka voi saada autolainaa?',
          content:
            'Perusvaatimukset autolainan saamiseen Rahalaitoksen kautta ovat säännölliset eläke– tai palkkatulot sekä puhtaat luottotiedot. Iältään autolainan hakijan tulee olla vähintään 20-vuotias.'
        },
        {
          title: 'Autolaina vai autorahoitus?',
          content:
            'Useimmat autonostajat tarvitsevat autoa hankkiessaan jonkinlaista rahoitusta. Autorahoitus ja autolaina eroavat toisistaan pääasiallisesti siinä, että autolainassa auton omistusoikeus siirtyy saman tien sivulle. Autorahoituksessa auto toimii lainan vakuutena ja esimerkiksi sen jälleenmyynti ennen rahoituksen takaisinmaksua voi olla vaikeaa. Autolainojen korot vaihtelevat erittäin paljon ja siksi kannattaa aina kilpailuttaa autolaina usealla lainantarjoajalla. Kilpailutus onnistuu kätevästi ja nopeasti Rahalaitoskilpailutuspalvelussa.'
        },
        {
          title: 'Tarvitsenko autolainaan vakuuksia tai takaajia?',
          content:
            'Autolainoja on olemassa sekä vakuudettomia että vakuudellisia. Kaikki Rahalaitos palvelussa tarjottavat autolainat ovat vakuudettomia. Et tarvitse myöskään takaajia, jos lainan saannin edellytykset muuten täyttyvät.'
        },
        {
          title: 'Mikä on autolainan korko?',
          content:
            'Rahalaitoksen kumppaneina toimiva autolainan tarjoajat tekevät itse lainapäätökset lainasummien sekä korkojen suhteen. He käyttävät apunaan mm. tulo- ja menoarviota,jolloin autolainatarjoukset ovat luotettavia ja ottavat huomioon asiakkaan maksukyvyn.'
        },
        {
          title: 'Kuinka kattavasti autolainat kilpailutetaan?',
          content:
            'Rahalaitos kilpailuttaa autolainat kattavasti useilla lainantarjoajilla.  Yhdellä autolainahakemuksella tavoitat jopa kymmeniä pankkeja ja rahoituslaitoksia. Autolainan kilpailuttaminen Rahalaitoksella on aina täysin ilmaista ja ei velvoita sinua mihinkään.'
        },
        {
          title: 'Voiko autolainan maksaa aiottua nopeammin pois?',
          content:
            'Ylimääräisiä suorituksia autolainaan voi tehdä milloin vain, jolloin lainan takaisinmaksuaika lyhenee. Voit myös maksaa koko autolainan yhdessä erässä pois. Korkoa maksetaan ainoastaan siltä ajalta, kun autolaina on ollut käytössä. Ennenaikaisesta takaisinmaksu järjestelystä kannattaa sopia aina rahoitusyhtiön kanssa, josta lainan on ottanut.'
        }
      ]
    }
  }
}
</script>
