<script>
import faq from '~/components/layout/faq'

export default {
  name: 'LainavertailuFAQAccordion',
  extends: faq,
  data () {
    return {
      questions: [
        {
          title: 'Miksi lainoja kannattaa vertailla?',
          content:
            'Lainojen hinnoissa on suuria eroja, kuten kaikissa muissakin tuotteissa ja palveluissa. Lainan takaisinmaksu kestää yleensä useita vuosia ja myös kuluja kertyy usean vuoden ajan. Pienikin ero korossa kertautuu kuukausi kuukaudelta ja kun vertaillaan kuluja koko laina-ajalta, ero onkin jo todella suuri. Paras lainavertailu näkyy puhtaasti säästönä rahapussissa.'
        },
        {
          title: 'Miksi kannattaa vertailla lainat Rahalaitoksen avulla?',
          content:
            'Rahalaitoksen kautta vertailet eri lainantarjoajien tarjouksia helposti ja nopeasti. Täyttämällä vain yhden hakemuksen, sinulla on mahdollisuus saada kerralla useampi tarjous vertailtavaksi. Lisäksi, lainan hakeminen ei velvoita sinua ottamaan lainaa ja Rahalaitoksen tarjoama vertailupalvelu on sinulle täysin ilmaista.'
        },
        {
          title: 'Mitä pankkeja on mukana lainavertailussa?',
          content:
            'Rahalaitoksen kumppaneina toimivat lainantarjoajat ovat kaikki tarkkaan valittuja luotettavuuden, nopeuden ja edullisuuden perusteella. Kaikki lainavertailussa mukana olevat kumppanimme on esitelty yhteistyökumppanit sivulla.'
        },
        {
          title: 'Miten vertaan eri lainatarjouksia?',
          content:
            'Lainatarjouksia vertailtaessa kannattaa ottaa huomioon lainan nimelliskoron lisäksi muitakin asioita. Koron lisäksi lainan kokonaiskuluihin vaikuttavat erilaiset kulut, kuten avausmaksut ja lainanhoitokulut. Näiden vertailu on tärkeää etenkin pienemmissä lainoissa, joissa ne muodostavat merkittävän osan kuluista. Rahalaitos ilmoittaa aina myös lainan todellisen vuosikoron tarjousten yhteydessä, mikä helpottaa lainavertailua. Lainantarjoajien tarjouksissa voi olla eroja myös takaisinmaksuajassa ja kuukausierissä. Kun vertaa lainaa kannattaa huomioida, että lainan takaisinmaksusuunnitelma on sinun maksukyvyllesi sopiva niiden osalta.'
        },
        {
          title: 'Kuinka paljon lainojen vertailu Rahalaitoksella maksaa?',
          content:
            'Palvelumme käyttäminen on aina täysin maksutonta. Voit täyttää hakemuksen ja vertailla lainatarjouksia palvelussamme milloin tahansa. Lainatarjousten pyytäminen ei myöskään sido sinua mihinkään, vaan voit halutessasi jättää kaikki vertailussa saamasi lainatarjoukset käyttämättä.'
        },
        {
          title: 'Kuka voi käyttää Rahalaitoksen lainanvertailupalvelua?',
          content:
            'Perusvaatimukset lainansaamiseen ovat säännölliset eläke– tai palkkatulot sekä puhtaat luottotiedot. Iältään lainanhakijan tulee olla vähintään 20-vuotias.'
        }
      ]
    }
  }
}
</script>
