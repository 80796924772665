<template>
  <div class="content">
    <breadcrumb
      :path="{'Talous': '/talous', 'Kulutusluottojen korot laskivat': ''}"
    />
    <img style="width: 100%;" :src="require(`~/assets/img/subpage/${this.$route.path.substring(1)}.jpg`)" :alt="this.$route.path.substring(1)">
    <p style="font-weight: 900;">
      Rahalaitos selvitti syyskuussa muuttuneen korkolain vaikutuksia kulutusluottojen korkoihin ja lainanhakijoiden käyttäytymiseen.
    </p>
    <h1>Selvitys: Kulutusluottojen korot laskivat jopa 6,5 % korkokaton seurauksena.  Katso millaisia hyötyjä lakimuutos toi lainanhakijoille.</h1>
    <p>
      Syyskuun alussa voimaan astuneen korkolain uudistuksen mukaan kulutusluottojen nimelliskorkojen pitää olla alle 20 prosenttia ja vuotuisten lainanhoitokulujen korkeintaan 150 euroa. Muutos on näkynyt selvästi kuluttajaluottomarkkinassa. Monet korkeakorkoisia pikavippejä tarjonneet toimijat ovat keskeyttäneet toimintansa ja korkotaso kulutusluotoissa on laskenut selvästi.
    </p>
    <p>
      Heti korkolain muutoksen jälkeen lainanhakijat ryntäsivät kilpailuttamaan ja yhdistämään vanhoja lainojaan. Ennen syyskuun ensimmäistä päivää otettuja lainoja lakimuutos ei automaattisesti päivittänyt.  Loppuvuoden aikana ja myös nyt vuoden vaihteen jälkeen lainojen päivittäminen uuden korkolain mukaiseksi on ollut aktiivista. Yhä moni suomalainen maksaa edelleen aivan liian suuria korkoja ja kuluja lainoistaan.
    </p>
    <h2>
      Kulutusluottojen korot laskivat selvästi
    </h2>
    <p>
      Suomen johtava lainanvälittäjä <nuxt-link to="/">Rahalaitos</nuxt-link> selvitti koroissa tapahtunutta muutosta viime vuoden osalta. Rahalaitos vertasi ennen lakimuutosta palvelunsa kautta otettuja lainoja lakimuutoksen jälkeen otettuihin ja muutos on merkittävä. Lakimuutos vaikutti kulutusluottojen korkotasoon heti, ja sen jälkeen otettujen lainojen todelliset vuosikorot ovat keskimäärin 6,51 prosenttia alempia kuin ennen lakimuutosta.
    </p>
    <p>
      Lainojen <nuxt-link to="/">kilpailuttaminen</nuxt-link> on viime vuosina tullut tutuksi suomalaisille ja yhä useampi käyttää lainantarpeen tullessa vastaan kilpailutuspalveluita varmistaakseen parhaan mahdollisen koron luotolle. Näin voidaan nopeasti yhdellä hakemuksella hakea lainatarjoukset jopa 25 pankilta. Kilpailutuspalvelussa lainatarjousten vertaileminen on helppoa ja nopeaa ja palvelu on hiottu selkeäksi ja tehokkaaksi.
    </p>
    <h2>
      Lainojen yhdistäminen kasvaa trendin omaisesti
    </h2>
    <p>
      Rahalaitoksen viime vuoden käyttäjätilastoihin perustuvan selvityksen mukaan eniten kasvava lainamuoto kulutusluotoissa on yhdistelylainat.  Useille suomalaisille on kertynyt erilaisiin tarpeisiin otettuja kulutusluottoja ja luottokorttivelkoja, joiden yhdistämisellä haetaan säästöjä lainanhoitokuluihin.
    </p>
    <p>
      Yhdistelylainat kasvoivat edellisvuoteen verrattuna koko viime vuoden ajan, mutta lakimuutoksen jälkeen kasvu yhdistelylainoissa lähes tuplaantui alkuvuodesta. Tämä kuvaa hyvin lainanhakijoiden tietoisuutta korkolain muutoksen merkityksestä lainan koroille ja kuluille, kerrotaan Rahalaitokselta.
    </p>
    <p>
      Rahalaitos selvitti myös lainan yhdistäneiden saamia säästöjä lainakuluistaan. Keskimäärin syyskuussa lainansa yhdistäneet säästivät lainanhoitokuluissaan 25-35 % verrattuna aiempiin useisiin pienempiin lainoihin. Tämä tarkoittaa yli 10 000 euron lainasummilla useiden satojen eurojen säästöä kuukaudessa.
    </p>
    <p>
      Useammat kulutusluotot kannattaa <nuxt-link to="/yhdista-lainat">yhdistää</nuxt-link>, sillä se on lähes takuuvarma keino säästää rahaa joka kuukausi. Yhdessä isossa lainassa korko on yleensä suhteessa matalampi kuin useassa pienessä. Lisäksi maksat tietenkin vain yhdet lainanhoitokulut.
    </p>
    <p>
      Lainan hakeminen ja kilpailuttaminen <nuxt-link to="/">Rahalaitoksen</nuxt-link> kautta on ilmaista eikä sido vielä mihinkään, vaan tarjouksiin voi tutustua kaikessa rauhassa. Tarvittaessa kaikki käy kuitenkin nopeasti. Kun tarjouksen hyväksyy, tulevat rahat tilille jopa saman päivän aikana.
    </p>
    <p><u>Kokeile Rahalaitoksen lainalaskurilla, millaisen kuukausierän voisit saada uudelle lainallesi.</u></p>
    <span class="miniform-header"> Lainan kilpailutus 70 000 € asti </span>
    <mini-form />
    <share text="Selvitys: Kulutusluottojen korot laskivat jopa 6,5 % korkokaton seurauksena." />
  </div>
</template>

<script>
export default {
  layout: 'articles',
  name: 'KulutusluottojenKorot',
  components: {
    miniForm: () => import( '~/components/subpage/miniform' ),
    share: () => import( '~/components/subpage/share' ),
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  head () {
    return {
      title: 'Kulutusluottojen korot laskivat jopa 6,5 % korkokaton seurauksena.',
      meta: [
        { hid: 'description', name: 'description', content: 'Rahalaitos selvitti syyskuussa muuttuneen korkolain vaikutuksia kulutusluottojen korkoihin ja lainanhakijoiden käyttäytymiseen.' }
      ]
    }
  }
}
</script>
