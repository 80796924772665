<template>
	<div class="content">
    <breadcrumb
      :path="{'Lainaa': '/lainaa', 'Lainahakemus': ''}"
    />
		<div class="subpage_floating">
			<img :src="require(`~/assets/img/subpage/${this.$route.path.substring(1)}.jpg`)" :alt="this.$route.path.substring(1)">
			<sliders class="is-hidden-touch" />
		</div>
		<h1>
			Tee lainahakemus helposti verkossa
		</h1>
		<h3>
			Saat yhdellä lainahakemuksella tarjoukset jopa 25 pankilta ja luotonmyöntäjältä
		</h3>
		<p>
			Lainahakemusten täyttäminen pankeille ja rahoituslaitoksille haukkaa runsaasti aikaasi, mikäli haluat vertailla lainakuluja ja -ehtoja useiden eri lainantarjoajien välillä. Rahalaitos on kotimainen lainanvälittäjä, joka hoitaa saman asian helpommin: täytä yksi lainahakemus ja saat parhaimmassa tapauksessa tarjoukset jopa 25 pankilta ja luotonmyöntäjältä.
		</p>
		<!-- Slider joka näkyy vain mobiilissa -->
		<sliders class="is-hidden-desktop" />
		<p>
			Vuodesta 2011 lähtien olemme kilpailuttaneet lainoja jo kymmenille tuhansille suomalaisille ja teemme yhteistyötä kaikkien merkittävimpien verkossa toimivien rahoituslaitosten ja lainantarjoajien kanssa. Palkkion saamme yhteistyökumppaneiltamme, joten sinulle lainahakemuksen täyttäminen on aina ilmaista ja ei-sitovaa.
		</p>
		<p>
			Hyväksytyn lainahakemuksen jälkeen voit vertailla saamiasi tarjouksia rauhassa ja päättää sen jälkeen, otatko lainaa vai et. Vain vertailemalla voit varmistaa, että saat lainasi halvimmalla mahdollisella hinnalla.
		</p>
		<h3>
			Näin lainahakemuksen tekeminen sujuu käytännössä
		</h3>
		<p>
			Palvelumme avulla voit hakea lainaa 500-70 000 euroa 1-15 vuoden takaisinmaksuajalla. Kilpailuttamamme lainat ovat vakuudettomia, eli et tarvitse toista henkilötakaajaa tai lainan takaisinmaksun pantiksi asetettavaa omaisuusvakuutta.
		</p>
		<p>
			Kun täytät lainalomakkeen sivuillamme, saat lainapäätöksen välittömästi. Hyväksytyn lainapäätöksen jälkeen yli 25 pankkia ja luotonmyöntäjää kilpailee lainastasi, ja ensimmäiset tarjoukset saat useimmiten jo muutaman minuutin sisällä.
		</p>
		<p>
			Saamiasi tarjouksia voit vertailla verkkosivujemme Oma Rahalaitos -palvelussa, johon kirjautumista varten lähetämme sinulle salasanan sähköpostitse ja tekstiviestillä.
		</p>
		<p>
			Voit valita lukuisten tarjousten joukosta halvimman vaihtoehdon tai jättää lainan kokonaan ottamatta, mikäli sopivaa vaihtoehtoa ei löydy. Tästä ei aiheudu minkäänlaisia kuluja, sillä lainahakemuksen täyttäminen ei sido sinua mihinkään.
		</p>
		<h3>
			Lainahakemus on mahdollinen, mikäli täytät nämä ehdot
		</h3>
		<p>
			Kaikki yhteistyökumppanimme ovat vastuullisia luotonmyöntäjiä, joten ne eivät myönnä lainoja kenelle tahansa. Jotta lainahakemus voidaan hyväksyä, sinun tulee täyttää seuraavat kriteerit:
		</p>
		<ul>
			<li>
				Ikä vähintään 20 vuotta.
			</li>
			<li>
				Palkka-, eläke- tai muut tulot yli 600 euroa kuukaudessa.
			</li>
			<li>
				Säntillisyys raha-asioiden hoidossa, eli sinulla ei saa olla julkisia maksuhäiriömerkintöjä.
			</li>
		</ul>
		<p>
			Luotonmyöntäjät ja pankit arvioivat lainankantokykysi hakemukseen syöttämiesi tietojen perusteella. Verkkosivuiltamme löytyvän lainalaskurin avulla voit tarkastella suuntaa-antavia esimerkkejä erisuuruisten lainojen kuukausieristä ja -kuluista eripituisilla laina-ajoilla.
		</p>
		<p>
			Saamasi tarjoukset ovat kuitenkin aina henkilökohtaisia, eli vain lainahakemuksen täyttämällä saat selville sinulle tarjottavan halvimman mahdollisen korkotason. Valitse siis itsellesi soveltuvin lainasumma nyt ja täytä ilmainen lainahakemus muutamassa minuutissa.
		</p>
	</div>
</template>

<script>
import sliders from '~/components/subpage/sliders'

export default {
  layout: 'subpage',
  name: 'LainaHakemus',
  components: {
    sliders,
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  async fetch ( { store } ) {
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Parhaat lainatarjoukset',
        subtitle: 'Paras tarjous löytyy aina vertailemalla',
        link: '/parhaat-lainatarjoukset'
      },
      {
        title: 'Lainaa 50 000 €',
        subtitle: 'Ilman vakuuksia tai takaajia',
        link: '/lainaa-50000-euroa'
      },
      {
        title: 'Yhdistä vanhat lainasi',
        subtitle: 'Säästä rahaa ja helpota arkeasi',
        link: '/yhdista-lainat'
      }
    ] )
  },
  head () {
    return {
      title: 'Lainahakemus - täytä helppo lainahakemus netisssä nyt!',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Tee lainahakemus helposti verkossa - saat yhdellä hakemuksella tarjoukset jopa 25 pankilta ja luotonmyöntäjältä. Kokeile heti!'
        }
      ]
    }
  }
}
</script>
