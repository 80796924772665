<template>
  <div class="content">
    <breadcrumb
      :path="{'Lainaa': '/lainaa', 'Säästä yli 300 euroa kuukaudessa': ''}"
    />
    <img
      style="width: 100%;"
      :src="require(
        `~/assets/img/subpage/${this.$route.path.substring(1)}.jpg`
      )"
      :alt="this.$route.path.substring(1)"
    >
    <p style="font-weight: 900;">
      Joskus elämäntilanne vaikuttaa taloustilanteeseen nopeasti.
      Lue miten voit säästää merkittäviä summia kuukausittain
      yhdistelemällä lainasi.
    </p>
    <h1>
      Piia päätti hyödyntää laskeneen korkotason – Säästöä
      yli 300 euroa kuukaudessa.
    </h1>
    <p>
      Piia, 45, on Espoolainen perheenäiti, jonka taloustilanne alkoi
      luisumaan eron jälkeen. Vaikka Piialla on selvästi suomalaisten
      keskiarvopalkkaa korkeammat tulot ja vakituinen työ esimiestehtävissä,
      hän huomasi henkilökohtaisen taloutensa menevän huonoon suuntaan.
    </p>
    <p>
      Hän ei halunnut lasten kärsivän vanhempien eron vuoksi. Vuokranmaksu
      yksin ja halu pitää kiinni eroa edeltävästä elintasosta alkoi
      kuitenkin johtamaan lainojen kerääntymiseen. Julkisuudessakin ollut
      korkolain tiukentuminen ja uutiset korkotason laskusta saivat
      Piian kiinnostumaan mahdollisesta yhdistää kulutusta varten otetut
      luottonsa ja hän päätti kilpailuttaa lainansa Rahalaitoksella.
    </p>
    <p>
      Tältä lainoihin kuluvat menot näyttivät ennen ja jälkeen
      kilpailuttamisen ja lainojen
      <nuxt-link to="/yhdista-lainat">yhdistämisen.</nuxt-link>
    </p>
    <p>
      Lainaa on kertynyt lainaa seuraavasti:
    </p>
    <table>
      <tr>
        <td>Laina</td>
        <td>Lyhennys</td>
      </tr>
      <tr><td>Autolaina 15000 €</td> <td>250 e /kk</td></tr>
      <tr><td>Remonttilaina 4800 €</td> <td>97 e /kk</td></tr>
      <tr><td>Kulutusluotto  2000 €</td> <td>100 e /kk</td></tr>
      <tr><td>Osamaksu huonekalut 3000 €</td> <td>60 e /kk</td></tr>
      <tr><td>Luottokortti a 2000 €</td> <td>100 e /kk</td></tr>
      <tr><td>Luottokortti b 1500 €</td> <td>100 e /kk</td></tr>
    </table>
    <p>
      Lainoja yhteensä 28 300e, lyhennykset yhteensä 707e/kk
    </p>
    <p>
      Kun Piia valitsi Rahalaitoksen lainavertailun kautta edullisimman
      saamansa tarjouksen, hän päätyi yhdistämään lainansa yhteen suurempaan
      30 000 euron lainaan. Nyt hän lyhentää lainaa yhdeksän vuoden
      maksusuunnitelmalla ja 387 euron kuukausierillä.
    </p>
    <p>
      Näin hänen kuukausittainen lyhennyseränsä laski 320 euroa aikaisemmasta
      summasta ja korkotaso putosi reilusti aikaisempiin lainoihin nähden.
      Yhdistelylainan korko on hänellä nyt reilusti alle 10 %, kun se useissa
      aiemmissa lainoissa oli ollut lähellä 20 % tasoa.
    </p>
    <p>
      Piia kokee elämänsä helpottuneen myös siinä, että hänellä on
      muistettavana enää yksi lyhennyspäivä kuukaudessa. Tällä tavalla
      hänen on huomattavasti helpompi pitää perheen talous järjestyksessä,
      kun erillisten lainojen maksut eivät enää eräänny useampana päivänä
      kuukaudessa. Tämä varmasti ilahduttaisi ja tasapainottaisi perheen
      kuin perheen taloudenhoitoa.
    </p>
    <p>
      Rahalaitos muistuttaa, että lainojen yhdistämisellä voi vaikeassa
      elämäntilanteessa käsistä karanneen taloustilanteen saada haltuun.
      Tällä hetkellä on erittäin hyvä aika kilpailuttaa ja
      <nuxt-link to="/yhdista-lainat">yhdistää lainat</nuxt-link>,
      koska korkotaso on laskenut. Mitä nopeammin tilanteessa toimii, sitä
      enemmän etua saa myös vuoden loppuun voimassa olevasta väliaikaisesta
      korkokatosta.
    </p>
    <p>
      <nuxt-link to="/yhdista-lainat">
        Täytä nyt lainahakemus muutamassa minuutissa ja näet
        millaisen koron sinä voisit saada lainaasi.
      </nuxt-link>
    </p>
    <span class="miniform-header">Lainan kilpailutus 70 000 € asti</span>
    <mini-form go-to-url="/yhdista-lainat"> </mini-form>
    <share
      text="Piia päätti hyödyntää laskeneen
        korkotason – Säästöä yli 300 euroa kuukaudessa."
    />
  </div>
</template>

<script>
export default {
  layout: 'articles',
  name: 'PiiaSaastaaYli300Euroa',
  components: {
    miniForm: () => import('~/components/subpage/miniform'),
    share: () => import('~/components/subpage/share'),
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  head () {
    return {
      title: 'Säästöä yli 300 euroa kuukaudessa',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Joskus elämäntilanne vaikuttaa taloustilanteeseen
            nopeasti. Lue miten voit säästää merkittäviä summia
            kuukausittain yhdistelemällä lainasi`
        }
      ]
    }
  }
}
</script>
