<template>
  <div class="content">
    <breadcrumb
      :path="{'Talous': '/talous', 'Korkokattoa jatkettiin syksyyn': ''}"
    />
    <img
      style="width: 100%;"
      :src="require(
        `~/assets/img/subpage/${this.$route.path.substring(1)}.jpg`
      )"
      :alt="this.$route.path.substring(1)"
    >
    <p style="font-weight: 900;">
      Korkokatto on laskenut selvästi kulutusluottojen hintaa.
    </p>
    <h1>
      Korkokattoa jatkettiin syksyyn – toimi näin, jos sinulla on kulutusluottoja
    </h1>
    <p style="font-weight: 900;">
      Heinäkuussa säädettyä väliaikaista 10 prosentin korkokattoa jatkettiin syyskuun loppuun. Varoitus, korkokaton aikana otetun lainan korko saattaa nousta syyskuun jälkeen.
    </p>
    <p>
      Vakuudettomien lainojen korkokatto on vaikuttanut kulutusluottojen korkoihin. Väliaikaista kymmenen prosentin korkokattoa päätettiin jatkaa syyskuun loppuun. Kilpailuttamalla uudet tai vanhat lainat nyt alkuvuodesta voi saada hyvän säästön. Syyskuun jälkeen korkokaton aikana otettujen lainojen korkotaso palautuu lainasopimuksessa määritellylle tasolle, joka voi olla korkeampi, kuin korkokaton aikana peritty korko.
    </p>
    <p>
      Viime vuoden aikana vakuudettomien lainojen korkotaso laski selvästi. Väliaikaisen korkokaton lisäksi korkotason laskuun vaikutti kilpailun kiristyminen kuluttajien varovaisuuden lisäännyttyä henkilökohtaisen talouden epävarmuuden vuoksi. Nyt kannattaa <nuxt-link to="/">kilpailuttaa</nuxt-link> tai yhdistää vanhat lainat ja luotot ja hyödyntää väliaikaisen korkokaton vaikutus mahdollisimman hyvin.
    </p>
    <h2>
      Lainojen yhdistäminen kannattaa
    </h2>
    <p>
      Yhdistelylainojen ottaminen on lisääntynyt selvästi. Lainojen yhdistämisellä tavoitellaan lainakulujen pienentämistä. Kulutusluottojen korkotason laskettua niiden, joilla on useita vanhoja lainoja, on erityksen kannattavaa kilpailuttaa ja yhdistää vanhat lainat. Lainoja yhdistäessä kannattaa myös huomioida mahdollisten luottokorttien saldot. Väliaikainen korkokatto ei koske luottokorteista perittävää korkoa ja niiden korko saattaa olla selvästi vakuudetonta kulutusluottoa kalliimpi.
    </p>
    <p>
      Korkokatto ei vaikuta vanhoihin lainoihin. Tämä tarkoittaa sitä, että jos laina on otettu ennen vuodenvaihdetta ei korkokatto muuta nykyistä korkoa mitenkään.
    </p>
    <p>
      Vuoden 2021 korkokatto rajoittaa vain sellaisten lainojen nimelliskorot 10 prosenttiin, jotka nostetaan uuden väliaikaisen lainsäädännön aikana. Myös vuoden 2020 aikana tehdyt sopimukset jäävät näin ollen vuoden 2021 korkokaton ulkopuolelle. Eli jos haluaa saada lainakuluihin säästöä korkokaton avulla, on laina kilpailutettava uudestaan ja vanha laina maksettava pois uudella lainalla.
    </p>
    <p>
      <nuxt-link to="/">Kokeile lainojen yhdistämistä Rahalaitoksella. Se on nopeaa, ilmaista eikä sido mihinkään.</nuxt-link>
    </p>
    <h2>
      Isompien ostosten rahoituksessa laina voi tulla halvemmaksi kuin osamaksu tai luottokortti
    </h2>
    <p>
      Jos suunnittelet suurempia ostoksia, joihin sinulla ei ole vielä kokonaan rahoitusta, kannattaa miettiä tarkkaan, miten rahoitat hankinnan. Laina saattaa olla edullisin ratkaisu. Väliaikainen korkoja säätelevä lakimuutos ei nimittäin koske luottokortteja tai osamaksuja, joiden korko voi olla yhä jopa 20 %.<nuxt-link to="/">Kilpailutuspalvelun</nuxt-link> kautta otettu vakuudeton laina voi tulla halvemmaksi kuin osamaksu tai luottokortin käyttö.
    </p>
    <h2>
      Kilpailuttamalla varmistat, ettet maksa liikaa korkoja
    </h2>
    <p>
      Rahalaitos kilpailuttaa lainasi turvallisesti 25 pankilla ja lainantarjoajalla, joiden kanssa se tekee yhteistyötä. Sinun ei tarvitse tehdä muuta, kuin täyttää yksi hakemus Rahalaitoksen sivuilla. Hakemuksen lähettäminen ei sido sinua vielä mihinkään, vaan voit rauhassa katsoa, millaisia tarjouksia saat, ja päättää sitten, hyväksytkö niistä jonkun.
    </p>
    <p>
      Kilpailuttaminen kannattaa, vaikka vanhojen lainojesi korot olisivatkin alle 10 prosentin. On hyvin mahdollista, että löydät silti lainantarjoajien koventuneen kilpailun ansiosta paremman tarjouksen Rahalaitoksen yhteistyökumppaneilta. Lainan kilpailuttamisen avulla on mahdollista säästää kymmeniä, jopa satoja euroja kuukaudessa.
    </p>
    <p>
      Varmista, että saat parhaan mahdollisimman lainan –
      <nuxt-link to="/">
        täytä lainahakemus ja katso, millaisen koron voit saada.
      </nuxt-link>
    </p>
    <span class="miniform-header">Lainan kilpailutus 70 000 € asti</span>
    <mini-form />
    <share
      text="Korkokattoa jatkettiin syksyyn
        – toimi näin, jos sinulla on kulutusluottoja"
    />
  </div>
</template>

<script>
export default {
  layout: 'articles',
  name: 'KorkokattoaJatkettiinSyksyyn',
  components: {
    miniForm: () => import('~/components/subpage/miniform'),
    share: () => import('~/components/subpage/share'),
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  head () {
    return {
      title: `Korkokattoa jatkettiin syksyyn – toimi näin, jos sinulla on kulutusluottoja`,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Heinäkuussa säädettyä väliaikaista 10 prosentin korkokattoa jatkettiin syyskuun loppuun. Varoitus, korkokaton aikana otetun lainan korko saattaa nousta syyskuun jälkeen.`
        }
      ]
    }
  }
}
</script>
