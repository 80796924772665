import { render, staticRenderFns } from "./piia-saastaa-yli-300-euroa-kuukaudessa-laskeneen-korkokaton-ansiosta.vue?vue&type=template&id=1dbf06a1&"
import script from "./piia-saastaa-yli-300-euroa-kuukaudessa-laskeneen-korkokaton-ansiosta.vue?vue&type=script&lang=js&"
export * from "./piia-saastaa-yli-300-euroa-kuukaudessa-laskeneen-korkokaton-ansiosta.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports